<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'PartyOrganizationAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="listQuery.param.name" placeholder="请输入名称" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'PartyOrganizationEditButton'" size="small" type="text" @click="handleEdit(row)">编辑</el-button>
        <el-button v-permission="'PartyOrganizationParty'" size="small" type="text" @click="goParty(row.id)">党员列表</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'Organization',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      listQuery: {
        param: {}
      },
      tableColumns: [
        {
          prop: 'name',
          title: '名称'
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['partyOrganizationPublicPageList']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.partyOrganizationPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleEdit(row) {
      this.$refs.Form.handleEdit(row)
    },
    goParty(organizationId) {
      this.$router.push({ name: 'PartyOrganizationParty', params: { organizationId }})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
