<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
export default {
  name: 'Form',
  components: { RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formStatus: '',
      row: {},
      formModel: {
        name: '',
        tenant: []
      }
    }
  },
  computed: {
    formItem() {
      return {
        'name': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '名称',
          'maxlength': 255
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        }
      }
    },
    formRules() {
      return {
        name: [
          { required: true, message: '名称不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['partyOrganizationAdd', 'partyOrganizationUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(row) {
      this.init('edit', row)
    },
    init(formStatus, row) {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          this.row = row
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise(resolve => {
        if (this.formStatus !== 'add') {
          this.formModel = {
            id: this.row.id,
            name: this.row.name,
            tenant: this.row.tenant.split(',').slice(2)
          }
          resolve()
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = `1,101,${params.tenant.join(',')}`

          switch (this.formStatus) {
            case 'add':
              this.partyOrganizationAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.partyOrganizationUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
